<template>
  <!-- Edit help support modal -->
  <b-modal
    id="modal-edit-help-support"
    :visible="isEditHelpSupportModalShow"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    :title="$t('support.form.editHeader')"
    hide-footer
    @change="val => $emit('update:is-edit-help-support-modal-show', val)"
  >
    <template #default="{ hide }">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(handleOk)"
          @reset.prevent="resetModal"
        >
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="question"
                rules="required"
              >
                <b-form-group
                  :label="$t('support.form.question')"
                  label-for="question"
                >
                  <b-form-input
                    id="question"
                    v-model="data.question"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('support.form.questionPlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('support.form.category')"
                  label-for="category"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="data.selectedCategory"
                    input-id="question-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :clearable="false"
                    :reduce="item => (item.value ? item.value : item.label)"
                    taggable
                    :placeholder="$t('support.form.categoryPlaceholder')"
                    :options="
                      $store.state['landing-help-support'].categoryOptions
                    "
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <validation-provider
            #default="validationContext"
            name="answer"
            rules="required"
          >
            <b-form-group
              :label="$t('support.form.answer')"
              label-for="answer"
              :state="getValidationState(validationContext)"
            >
              <quill-editor v-model="data.answer" :options="snowOption" />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="modal-footer">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isEditQuestionLoading || isAddCategoryLoading"
            >
              <div v-if="isEditQuestionLoading || isAddCategoryLoading" class="d-flex align-center">
                <b-spinner type="grow" small class="mr-1"/>
                Loading...
              </div>
              <span v-else>{{ $t('common.save') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import { isValidObjectId } from '@core/utils/filter'

import useHelpSupportsList from './useHelpSupportsList'

import store from '@/store'

export default {
  components: {
    quillEditor,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: ['isEditHelpSupportModalActive', 'editedData', 'categoryOptions'],
    event: 'update:is-edit-help-support-modal-show',
  },
  props: {
    isEditHelpSupportModalShow: {
      type: Boolean,
      required: true,
    },
    editedData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      snowOption: {
        theme: 'snow',
        placeholder: 'Answer here...',
      },
      required,
    }
  },

  setup(props, { emit }) {
    const {
      fetchCategories,
      editQuestion,
      addCategory,
      isEditQuestionLoading,
      isAddCategoryLoading,
    } = useHelpSupportsList()

    const blankData = {
      question: '',
      answer: '',
      selectedCategory: '',
    }

    const data = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(blankData))
    }

    watch(
      () => props.isEditHelpSupportModalShow,
      val => {
        if (val) {
          if (
            store.state['landing-help-support'].categoryOptions.length === 0
          ) {
            fetchCategories().then(() => {
              // eslint-disable-next-line no-underscore-dangle
              data.value.selectedCategory = props.editedData.category_id
            })
          }
          // eslint-disable-next-line no-underscore-dangle
          data.value.question = props.editedData.question
          data.value.selectedCategory = props.editedData.category_id
          data.value.answer = props.editedData.answer
        }

        if (!val) {
          resetData()
        }
      },
    )

    const resetModal = () => {
      // resetData()
      emit('update:is-edit-help-support-modal-show', false)
    }

    const updateHelpSupport = () => {
      if (!isValidObjectId(data.value.selectedCategory)) {
        const optionInclude = store.state[
          'landing-help-support'
        ].categoryOptions.find(
          option =>
            option.label.toLowerCase() ===
            data.value.selectedCategory.toLowerCase(),
        )
        if (optionInclude) {
          data.value.selectedCategory = optionInclude.value
          editQuestion(0, {
            id: props.editedData._id,
            data: data.value,
          }).then(() => {
            emit('refetch-data')
            resetModal()
          })
          return
        }

        addCategory(0, { selectedCategory: data.value.selectedCategory }).then(
          res => {
            const { _id: id } = res.data.data
            data.value.selectedCategory = id
            editQuestion(0, {
              id: props.editedData._id,
              data: data.value,
            }).then(() => {
              emit('refetch-data')
              emit('update:is-edit-help-support-modal-show', false)
            })
          },
        )
      } else {
        editQuestion(0, { id: props.editedData._id, data: data.value }).then(
          () => {
            emit('refetch-data')
            emit('update:is-edit-help-support-modal-show', false)
          },
        )
      }
    }

    const { refFormObserver, getValidationState } = formValidation(resetData)

    const handleOk = () => {
      // eslint-disable-next-line no-underscore-dangle
      if (
        data.value.answer !== props.editedData.answer ||
        data.value.question !== props.editedData.title ||
        data.value.selectedCategory !== props.editedData.category_id
      ) {
        updateHelpSupport()
      } else {
        emit('update:is-edit-help-support-modal-show', false)
      }
    }

    return {
      data,
      handleOk,
      resetModal,
      isAddCategoryLoading,
      isEditQuestionLoading,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
