import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import { useAsyncState } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import swal from 'sweetalert2'

export default function useHelpSupportsList() {
  // Use toast
  const toast = useToast()

  const refHelpSupportListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'question', label: 'Question', sortable: true },
    { key: 'category', label: 'Category', sortable: true },
    { key: 'answer', label: 'Answer', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalHelpSupports = ref(0)
  const categoryOptions = ref([])
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refHelpSupportListTable.value
      ? refHelpSupportListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalHelpSupports.value,
    }
  })

  const refetchData = () => {
    refHelpSupportListTable.value.refresh()
  }

  watch(
    [currentPage, perPage, searchQuery, categoryFilter, statusFilter],
    () => {
      refetchData()
    },
  )

  // !! Add Question
  const {
    state: addQuestionState,
    isLoading: isAddQuestionLoading,
    execute: addQuestion,
  } = useAsyncState(
    async args => {
      const formData = new FormData()
      formData.append('title', args.data.helpSupportQuestion)
      formData.append('category_id', args.data.selected)
      formData.append('description', args.data.content)

      const response = await store.dispatch(
        'landing-help-support/addQuestion',
        formData,
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success creating new question',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating new question',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // !! Edit Question
  const {
    state: editQuestionState,
    isLoading: isEditQuestionLoading,
    execute: editQuestion,
  } = useAsyncState(
    async args => {
      const response = await store.dispatch(
        'landing-help-support/editQuestion',
        { id: args.id, data: args.data },
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success editing question',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error editing question',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // !! Delete Question
  const {
    state: deleteQuestionState,
    isLoading: isDeleteQuestionLoading,
    execute: deleteQuestion,
  } = useAsyncState(
    async args => {
      swal
        .fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this question!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.value) {
            await store
              .dispatch('landing-help-support/deleteQuestion', args._id)
              .then(response => {
                if (response.data.code === 200) {
                  swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Your question has been deleted.',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  }),
                    refetchData()
                } else {
                  swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
          } else if (result.dismiss === swal.DismissReason.cancel) {
            swal.fire({
              title: 'Cancelled',
              text: 'Your question is safe :)',
              icon: 'error',
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    null,
    { immediate: false },
  )

  // !! Add Category
  const {
    state: addCategoryState,
    isLoading: isAddCategoryLoading,
    execute: addCategory,
  } = useAsyncState(
    async args => {
      const response = await store.dispatch(
        'landing-help-support/addCategory',
        args.selectedCategory,
      )

      if (response.data.code !== true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating new question',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        store.commit('landing-help-support/setCategoryOptions', [])

        toast({
          component: ToastificationContent,
          props: {
            title: 'Success creating new question',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // !! Fetch Categories
  const {
    state: categoriesState,
    isLoading,
    execute: fetchCategories,
  } = useAsyncState(
    async () => {
      try {
        const response = await store.dispatch(
          'landing-help-support/fetchCategories',
        )

        if (response.data.code === 200) {
          response.data.data.categories.forEach(category => {
            categoryOptions.value.push({
              label: category.title,
              value: category._id,
            })

            store.commit(
              'landing-help-support/setCategoryOptions',
              categoryOptions.value,
            )
          })
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    [],
    { immediate: false },
  )

  const fetchHelpSupports = (ctx, callback) => {
    store
      .dispatch('landing-help-support/fetchQuestions', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category: categoryFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { faqdatas, total_data: total } = response.data.data
        const data = faqdatas.map(
          ({ category, category_id, desc, slug, title, _id }) => ({
            category,
            category_id,
            answer: desc,
            slug,
            question: title,
            _id,
          }),
        )
        callback(data)
        totalHelpSupports.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveHelpSupportRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveHelpSupportStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchHelpSupports,
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalHelpSupports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    isLoading,
    categoriesState,
    categoryOptions,
    refHelpSupportListTable,

    resolveHelpSupportRoleVariant,
    resolveHelpSupportStatusVariant,
    refetchData,

    // Add Question
    addQuestion,
    addQuestionState,
    isAddQuestionLoading,

    // Edit Question
    editQuestion,
    editQuestionState,
    isEditQuestionLoading,

    // Delete Question
    deleteQuestion,
    deleteQuestionState,
    isDeleteQuestionLoading,

    // Add Category
    addCategory,
    addCategoryState,
    isAddCategoryLoading,

    // Extra Filters
    categoryFilter,
    statusFilter,
  }
}
