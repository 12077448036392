<template>
  <div>
    <help-support-list-add-new
      v-if="$can('create', 'FaQ')"
      :is-add-new-help-support-modal-show.sync="isAddNewHelpSupportModalShow"
      @refetch-data="refetchData"
    />

    <help-support-edit
      v-if="$can('update', 'FaQ')"
      :is-edit-help-support-modal-show.sync="isEditHelpSupportModalShow"
      :edited-data.sync="editHelpSupportData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <help-supports-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="$can('create', 'FaQ')"
              variant="primary"
              @click="isAddNewHelpSupportModalShow = true"
            >
              {{ $t('support.addBtn') }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search') + '...'"
              />
              <v-select
                v-model="categoryFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state['landing-help-support'].categoryOptions"
                :reduce="item => item.value"
                class="category-filter-select"
                :placeholder="$t('common.filter.category')"
                label="label"
                :loading="isLoading"
                @open="fetch"
              >
                <template #spinner="{ loading }">
                  <b-spinner v-if="loading" variant="primary" small />
                  <span class="sr-only">Loading...</span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refHelpSupportListTable"
        class="position-relative custom-table"
        :items="fetchHelpSupports"
        responsive
        :fields="translatedTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('message.empty')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div class="text-center mt-2">
            <b-spinner variant="primary" label="Loading..." />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :id="`help-row-${data.item._id}-eye-icon`"
              icon="EyeIcon"
              class="cursor-pointer mx-1"
              size="16"
              @click="
                $router.push({
                  name: 'landing-help-support-view',
                  params: { slug: data.item.slug },
                })
              "
            />
            <b-tooltip
              :title="$t('support.tooltip.view')"
              class="cursor-pointer"
              :target="`help-row-${data.item._id}-eye-icon`"
            /> -->

            <feather-icon
              v-if="$can('update', 'FaQ')"
              :id="`help-row-${data.item._id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="openEditHelpSupportModal(data.item)"
            />
            <b-tooltip
              v-if="$can('update', 'FaQ')"
              :title="$t('support.tooltip.edit')"
              class="cursor-pointer"
              :target="`help-row-${data.item._id}-edit-icon`"
            />

            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :disabled="!$can('delete', 'FaQ')"
                @click="deleteQuestion(0, data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{
                  $t('common.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $tc('common.showing', dataMeta.of, {
                first: dataMeta.from,
                last: dataMeta.to,
                total: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalHelpSupports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useHelpSupportsList from './useHelpSupportsList'
import helpSupportStoreModule from '../helpSupportStoreModule'
import HelpSupportListAddNew from './HelpSupportListAddNew.vue'
import HelpSupportEdit from './HelpSupportEdit.vue'

export default {
  components: {
    HelpSupportListAddNew,
    HelpSupportEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`support.tableHeader.${column.key}`),
      }))
    },
  },
  methods: {},
  setup() {
    const HELP_SUPPORT_STORE_MODULE_NAME = 'landing-help-support'

    // Register module
    if (!store.hasModule(HELP_SUPPORT_STORE_MODULE_NAME))
      store.registerModule(
        HELP_SUPPORT_STORE_MODULE_NAME,
        helpSupportStoreModule,
      )

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HELP_SUPPORT_STORE_MODULE_NAME))
        store.unregisterModule(HELP_SUPPORT_STORE_MODULE_NAME)
    })

    const isAddNewHelpSupportModalShow = ref(false)
    const isEditHelpSupportModalShow = ref(false)
    const editHelpSupportData = ref({})

    const openEditHelpSupportModal = data => {
      editHelpSupportData.value = data
      isEditHelpSupportModalShow.value = true
    }

    const {
      fetchHelpSupports,
      fetchCategories,
      tableColumns,
      isLoading,
      perPage,
      currentPage,
      totalHelpSupports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHelpSupportListTable,
      refetchData,

      // Delete Question
      deleteQuestion,
      deleteQuestionState,
      isDeleteQuestionLoading,

      // Extra Filters
      categoryFilter,
      statusFilter,
    } = useHelpSupportsList()

    const fetch = () => {
      if (store.state['landing-help-support'].categoryOptions.length === 0)
        fetchCategories()
    }

    return {
      // Sidebar
      isAddNewHelpSupportModalShow,
      isEditHelpSupportModalShow,

      fetchHelpSupports,
      tableColumns,
      perPage,
      currentPage,
      totalHelpSupports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHelpSupportListTable,
      refetchData,
      openEditHelpSupportModal,
      editHelpSupportData,
      isLoading,
      fetch,

      // Delete Question
      deleteQuestion,
      deleteQuestionState,
      isDeleteQuestionLoading,

      // Extra Filters
      categoryFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.category-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// .custom-table td {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   max-width: 1px;
// }
</style>
