import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categoryOptions: [],
  },
  getters: {},
  mutations: {
    setCategoryOptions(state, categoryOptions) {
      state.categoryOptions = categoryOptions
    },
  },
  actions: {
    fetchQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/faq-modul/faq', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestion(ctx, { slug }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/faq-modul/faq/${slug}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuestion(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/faq-modul/faq/create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editQuestion(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/faq-modul/faq/update/${id}`, {
            title: data.question,
            description: data.answer,
            category_id: data.selectedCategory,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuestion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/faq-modul/faq/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/faq-modul/category/faq', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/faq-modul/category/faq/create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCategory(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/faq-modul/category/faq/update/${id}`, {
            title: data.title,
            description: data.description,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/faq-modul/category/faq/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
