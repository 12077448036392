<template>
  <!-- Add new help support modal -->
  <b-modal
    id="modal-new-help-support"
    :visible="isAddNewHelpSupportModalShow"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    :title="$t('support.form.header')"
    hide-footer
    @change="(val) => $emit('update:is-add-new-help-support-modal-show', val)"
  >

    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetModal"
        >
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="question"
                rules="required"
              >
                <b-form-group
                  :label="$t('support.form.question')"
                  label-for="question"
                >
                  <b-form-input
                    id="question"
                    v-model="data.helpSupportQuestion"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('support.form.questionPlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('support.form.category')"
                  label-for="category"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="data.selected"
                    input-id="question-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    taggable
                    :placeholder="$t('support.form.categoryPlaceholder')"
                    :create-option="category => ({ label: category, value: category })"
                    :reduce="(item) => item.value"
                    :options="$store.state['landing-help-support'].categoryOptions"
                    :loading="isLoading"
                    @open="fetch"
                  >
                    <template #spinner="{ loading }">
                      <b-spinner
                        v-if="loading"
                        variant="primary"
                        small
                      />
                      <span class="sr-only">Loading...</span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <validation-provider
            #default="validationContext"
            name="answer"
            rules="required"
          >
            <b-form-group
              :label="$t('support.form.answer')"
              label-for="answer"
              :state="getValidationState(validationContext)"
            >
              <quill-editor
                v-model="data.content"
                :options="snowOption"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="modal-footer">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isAddQuestionLoading || isAddCategoryLoading"
            >
              <div v-if="isAddQuestionLoading || isAddCategoryLoading" class="d-flex align-center">
                <b-spinner
                  type="grow"
                  small
                  class="mr-1"
                />
                Loading...
              </div>
              <span v-else>{{ $t('common.create') }}</span>
            </b-button>
          </div>
        </b-form>

      </validation-observer>

    </template>

  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BFormInput, BFormGroup, BForm, BRow, BCol, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { isValidObjectId } from '@core/utils/filter'
import useHelpSupportsList from './useHelpSupportsList'

import store from '@/store'

export default {
  components: {
    quillEditor,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isAddNewHelpSupportModalActive',
    event: 'update:is-add-new-help-support-modal-show',
  },
  props: {
    isAddNewHelpSupportModalShow: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      snowOption: {
        theme: 'snow',
        placeholder: 'Answer here...',
      },
      required,
    }
  },

  setup(props, { emit }) {
    const blankData = {
      helpSupportQuestion: '',
      content: '',
      selected: '',
    }

    const data = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(blankData))
    }

    const {
      fetchCategories, isLoading,
      addQuestion, isAddQuestionLoading,
      addCategory, isAddCategoryLoading,
    } = useHelpSupportsList()

    const resetModal = () => {
      resetData()
      emit('update:is-add-new-help-support-modal-show', false)
    }

    const onSubmit = () => {
      if (!isValidObjectId(data.value.selected)) {
        const optionInclude = store.state['landing-help-support'].categoryOptions.find(option => option.label.toLowerCase() === data.value.selected.toLowerCase())
        if (optionInclude) {
          data.value.selected = optionInclude.value
          addQuestion(0, { data: data.value }).then(() => {
            emit('refetch-data')
            resetModal()
          })
          return
        }

        addCategory(0, { selectedCategory: data.value.selected }).then(res => {
          const { _id: id } = res.data.data
          data.value.selected = id
          addQuestion(0, { data: data.value }).then(() => {
            emit('refetch-data')
            resetModal()
          })
        })
      } else {
        addQuestion(0, { data: data.value }).then(() => {
          emit('refetch-data')
          resetModal()
        })
      }
    }

    const fetch = () => {
      if (store.state['landing-help-support'].categoryOptions.length === 0) fetchCategories()
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetData)

    return {
      data,
      onSubmit,
      resetModal,
      isLoading,
      isAddQuestionLoading,
      isAddCategoryLoading,
      fetch,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
